import React, { useState } from 'react'
import { validateEmail } from '../utils'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import { base, colors, breakpoints, fonts } from '../styles/variables'
import ArrowRight from './svg/arrowRight'
import { H5, TextContainer } from './shared/typography'

const FormContainer = styled('div')`
    background-color: white;
    padding: 1rem;
    margin: 0 auto;
    width: 100%;
`

const Form = styled('form')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    grid-template-columns: 1fr;
  }
  input {
    font-size: 1.25rem;
    font-family: ${ fonts.poppins };
    border-radius: 0;
    width: 100%;
    border: none;
    border-bottom: 2px solid ${ colors.text };
    :focus {
        outline: none;
        border-bottom-color: ${ colors.purple }
    }
  }
  input[type='text'],
  input[type='email'] {
    background: none;
    color: ${ colors.text };
    text-align: left;
    padding: 20px 0;
    box-shadow: none;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      width: calc(100%);
      margin-right: 0px;
      padding: 20px 0;
      text-align: left;
    }
    ::placeholder {
      text-transform: uppercase;
      letter-spacing: 0.4em;
      font-size: 14px;
      color: #c4c4c4;
      @media screen and (max-width: ${ breakpoints.tablet }px) {
        font-size: 12px;
      }
    }
    &:not(output):-moz-ui-:invalid {
      box-shadow: none;
    }
    &:invalid {
      box-shadow: none;
    }
    &.invalid {
      border-bottom-color: ${ colors.orange };
    }
  }
  input[type='email'] {
    width: 100%;
  }
  input[type='text'] {
    width: 100%;
    margin-right: 10px;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      margin-right: 0px;
    }
  }
  button[type='submit'] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: ${ colors.purple };
    border: none;
    border-bottom: 1px solid ${ colors.neutral };
    padding: 20px 0;
    cursor: pointer;
    color: ${ colors.text };
    font-family: ${ fonts.poppins };
    font-size: ${ base.value * 1.5 }px;
    line-height: ${ base.value * 2.75 }px;
    font-weight: 600;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.2s;
    span {
      
    }
    svg {
      transition: all 0.2s;
      fill: ${ colors.text };
      width: 80px;
      margin-left: 1rem;
    }
    &.disabled {
      cursor: no-drop;
      background: #f7f7f7;
    }
    &:not(.disabled) {
        &:focus {
            outline: none;
        }
        &:hover, &:focus {
            color: ${ colors.neutral };
            svg {
                fill: ${ colors.neutral };
            }
        }
    }
  }
`

const LeadForm = ({ data }) => {
  const [name, setName] = useState('')
  const [brand, setBrand] = useState('')
  const [email, setEmail] = useState('')
  const [subscribed, setSubscribed] = useState('')
  const [errorMsg, seterrorMsg] = useState(null)

  const validEmail = validateEmail(email)

  function encode (data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const onSubscribeClick = event => {
    event.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': event.target.getAttribute('name'),
        name: name,
        brand: brand,
        email: email
      })
    }).then(() => setSubscribed(true)).catch(error => seterrorMsg(error))
  }

  return (
    <FormContainer
    >
      {errorMsg}
      {!subscribed
        ? (
          <Form onSubmit={onSubscribeClick} data-netlify="true" name="lead">
            <input type="hidden" name="form-name" value="lead" />

            <input
              aria-label="Your Name"
              type="text"
              name="name"
              placeholder="Your Name"
              onChange={e => setName(e.target.value)}
              value={name}
            />

            <input
              aria-label="Your Brand"
              type="text"
              name="brand"
              placeholder="Your Brand"
              onChange={e => setBrand(e.target.value)}
              value={brand}
            />

            <input
              aria-label="Your Email"
              className={!validEmail && email.length > 1 ? 'invalid' : ''}
              type="email"
              name="email"
              placeholder="email@company.com"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />

            <button title="Let's Go" type="submit" disabled={!validEmail} className={!validEmail ? 'disabled' : ''}>
              <span>Let's Go</span>
              <ArrowRight />
            </button>
            {errorMsg ? <p className="error-text">{errorMsg}</p> : ''}
          </Form>
        )
        : (
          <TextContainer ta="center">
            <H5>You have great judgment. We'll be in touch soon!</H5>
          </TextContainer>
        )}
    </FormContainer>
  )
}

export default LeadForm
